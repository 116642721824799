import React from "react";
import Tocka from '../tocka/Tocka';
import karta from '../../../assets/images/karta.jpg';
import logo from '../../../assets/images/logo.png';
//import logo1 from '../../../assets/images/logo1.png';
//import logo2 from '../../../assets/images/logo2.png';
import './Karta.css';

const Karta = (props) => {
    
//[{"id":"4","prikaz":"3","naslov":"Spurn","tekst":null,"left":"12.99%","top":"19.40%","ajtemi":[{"id":"32","naslov":"VR 360","tekst":"","tip":"4","fajl":["fajlovi\/32\/Spurn past ands present_copy.png"],"thumb":""}]},{"id":"5","prikaz":"1","naslov":"sdfsddsf","tekst":null,"left":"20.31%","top":"17.31%","ajtemi":[]}]
/*
<img src={logo1} width="652" style={{position:'absolute', right:'14vw', top:'45vh'}} alt="" />
        <img src={logo2} width="310" style={{position:'absolute', right:'5vw', top:'45vh'}} alt="" />
*/
    return <div className='karta'>
        <h1>Seagrass<br />Beds</h1>
        {props.lokacije.map((element, index) => 
            <Tocka domena={props.domena} key={element.id} prikaz={element.prikaz} lbl={element.naslov} naslov={element.naslov} tekst={element.tekst} br={(index+1)} left={element.left} top={element.top} ajtemi={element.ajtemi} />                  
        
        )}      
        <img src={logo} width="531" style={{position:'absolute', right:'5vw', top:'45vh', borderRadius:'30px'}} alt="" />
        <img src={karta} width="100%" alt="" />
    </div>;
}

export default Karta;