import React, {useState} from 'react';
import TockaOpsirno from '../tockaOpsirno/TockaOpsirno'
import './Tocka.css';


//import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const TockaHoder = (props) => {
    return (<div className='tocka_holder' style={props.stil}>
                <div className={'tocka_'+props.prikaz}>
                    {props.children}
                </div>
            </div>);
}
/*
const LblCrta = (props) => {
    return <div className='lbl_crta'>
        <div className='tocka_tekst'>
            {props.naslov}
            <div className={'crta-'+ props.pozicija}></div> 
        </div> 
    </div>
}
*/
const Tocka = (props) => {
    
    //const [fullscreen, setFullscreen] = useState(true);
    const [show, setShow] = useState(false);
  
    function handleShow(breakpoint) {
      //setFullscreen(breakpoint);
      setShow(true);
    }

    //const handleClose = () => setShow(false);
    //const handleShow = () => setShow(true);
    //console.log(props.ajtemi);
    return (
        <div>

            <TockaHoder prikaz={props.prikaz} stil={{left: props.left, top:props.top}}>
                <div onClick={handleShow}>
                    {props.naslov}                               
                </div>     
                <span onClick={handleShow}>{props.br}</span>   
            </TockaHoder>           


            <Modal show={show}
                    onHide={() => setShow(false)}
                    dialogClassName="sirina-90 prozirno"
                    aria-labelledby="contained-modal-title-vcenter"                    
                    centered>
                {/*<Modal.Header closeButton>
                    <Modal.Title>{props.naslov}</Modal.Title>
                </Modal.Header>*/}
                <Modal.Body><TockaOpsirno domena={props.domena} ajtemi={props.ajtemi}/></Modal.Body>
            </Modal>
        </div>
    );
} 

export default Tocka;