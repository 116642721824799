
import React, {useState} from "react";
import Karta from "./components/UI/karta/Karta";

import ThemeProvider from 'react-bootstrap/ThemeProvider'
import 'bootstrap/dist/css/bootstrap.min.css';

import './test.css';


function App() {
    const dataFile = 'https://deep.idea-innovations.co.uk/seagrass-admin/json.php';//'/data.json';//
    const domena = 'https://deep.idea-innovations.co.uk/seagrass-admin/';//'';//
  
    const [lokacije, setLokacije] = useState([]);

    async function ucitajPodatke(){
        const response = await fetch(dataFile);
        const xxx = await response.json();
        setLokacije(xxx[0]);
        console.log(xxx[0]);
    }
    if(lokacije.length === 0)
      ucitajPodatke();

  /*
  let idleTime = 0;

  setInterval(timerIncrement, 3000);
  onclick = () => idleTime = 0;
  onmousemove = () => idleTime = 0;
  onmousedown = () => {idleTime = 0;console.log('AAA: '+idleTime)};
  function timerIncrement() {
      idleTime = idleTime + 1;
      console.log('idleTime: '+idleTime);
      if (idleTime > 10) { // 1.5 min
          window.location.reload();
      }
  }
  */

  return (
    <ThemeProvider
      breakpoints={['xxxl', 'xxl', 'xl', 'lg', 'md', 'sm', 'xs', 'xxs']}
      minBreakpoint="xxs"
    >
      <Karta lokacije={lokacije} domena={domena} />
    </ThemeProvider>
    
  );
}

export default App;