import React from "react";
import Slider from "react-touch-drag-slider";


function Galerija(props) {

  const images = props.ajtem.fajl;
  return (
    <div style={{'height':'100vh'}}>
     <Slider>
        {images.map(({ url }, index) => (
          <img src={props.domena+'seagrass-admin/'+url} key={index} alt='' />
        ))}
      </Slider>
    </div>
  );
}

export default Galerija;
