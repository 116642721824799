import React, {useEffect, useRef} from "react";



const Video = props => {

    const videoEl = useRef(null);

    const attemptPlay = () => {
        videoEl &&
        videoEl.current &&
        videoEl.current.play().catch(error => {
            console.error("Error attempting to play", error);
        });
    };

    //const myCallback = () => (console.log('Video has ended'));

    useEffect(() => {
        attemptPlay();
    }, []);

    console.log(props.ajtem.fajl);

    return (
            <video
            style={{ maxWidth: "100%", width: "100%", margin: "0 auto" }}
            playsInline
            loop
            muted
            src={props.domena+props.ajtem.fajl}
            ref={videoEl}
            //onEnded={() => myCallback()}
            />
    );


    //const fajl = 'https://test15.idea-innovations.co.uk/test.php?slika=test/'+props.ajtem.fajl;
    //return (<iframe allowfullscreen="" src={fajl} width="100%" height="436"></iframe>);
}


export default Video;