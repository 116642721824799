import React, {useRef, useEffect, useState} from "react";
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import css from './Tekst.module.css';

const Krug = props => {

    return(
        <Col>
            <div className={css.prozirni_rub}>
                <div className={css.krug}>
                <table width="100%" height="100%">
                    <tbody>
                        <tr>
                        <td align="center" valign="middle" height="100%">
                            {props.tekst}
                        </td>
                        </tr>
                    </tbody>
                </table>
                    
                    
                    </div>
            </div>            
        </Col>
    )
}

const Tekst = props => {
    /*const [height, setHeight] = useState(200);
    const ref = useRef();
    const fajl = props.domena+'vr.php?slika=seagrass-admin/'+props.ajtem.fajl;

    useEffect(() => {
      setHeight((ref.current.clientWidth / 2));
    })*/

    return (<Container fluid>
        <Row id={props.id}>
            <Col>
                <div className={css.naslov}>
                    <div className={css.naslov2}>
                        <h1 className={css.h1}>{props.ajtem.naslov}</h1>    
                        <h2 className={css.h2}>{props.ajtem.tekst}</h2>  
                    </div>
                </div>
            </Col>                
        </Row>  
        <Row md={3} id={props.id} style={{marginTop:'100px'}}>
            <Krug tekst={props.ajtem.tekst1} />  
            <Krug tekst={props.ajtem.tekst2} />  
            <Krug tekst={props.ajtem.tekst3} />          
        </Row>       
      </Container>);
}


export default Tekst;